import React, { createContext, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import First from './First';
import Second from './Second';
import Sorder from './Sorder';

export const AppContext = createContext({ importedWX: false, setImportedWX: null });

function Home() {
  const [importedWX, setImportedWX] = useState(false);
  return (
    <AppContext.Provider value={{importedWX, setImportedWX}}>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="first" element={<First />} />
          <Route path="/second" element={<Second />} />
          <Route path="/sorder" element={<Sorder />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default Home;
