import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Container,
  Col,
  Form,
  Button,
  Stack,
  Alert,
} from "react-bootstrap";
import "./App.css";
import { AppContext } from "./home";
import { initializeWeChatSDK } from "./utils";

export const baseUrl = "https://sxxl.sifan-gift.com/tickets/";
// export const baseUrl = 'http://192.168.2.55:8081/';

const TicketInfo = (props) => {
  const ticket = props?.ticket;
  return (
    ticket && (
      <div className="p-2 mb-4 info-bg rounded-3">
        <Row>
          <Col xs={4}>卡券编号</Col>
          <Col xs={8}>{ticket.tid}</Col>
        </Row>
        <Row>
          <Col xs={4}>卡券名称：</Col>
          <Col xs={8}>{ticket.name}</Col>
        </Row>
        <Row>
          <Col xs={4}>卡券详情：</Col>
          <Col xs={8}>{ticket.info}</Col>
        </Row>
        <Row>
          <Col xs={4}>有效期：</Col>
          <Col xs={8}>永久有效</Col>
        </Row>
        <Row>
          <Col xs={4}>电话：</Col>
          <Col xs={8}>{ticket.cphone}</Col>
        </Row>
        <Row>
          <Col xs={4}>店址：</Col>
          <Col xs={8}>{ticket.caddress}</Col>
        </Row>
      </div>
    )
  );
};

const SubmitForm = (props) => {
  const { tid, cid, timestamp, sign } = props?.myparams;
  const [show, toggleShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [linkname, setLinkname] = useState('');
  const [linkphone, setLinkphone] = useState('');
  const [linkaddress, setLinkaddress] = useState('');

  const { importedWX, setImportedWX } = useContext(AppContext);

  const GetWxAddress = () => {
    wx.openAddress({
      success: function (res) {
          var userName = res.userName; // 收货人姓名
          var postalCode = res.postalCode; // 邮编
          var provinceName = res.provinceName; // 国标收货地址第一级地址（省）
          var cityName = res.cityName; // 国标收货地址第二级地址（市）
          var countryName = res.countryName; // 国标收货地址第三级地址（国家）
          var detailInfo = res.detailInfo; // 详细收货地址信息
          var nationalCode = res.nationalCode; // 收货地址国家码
          var telNumber = res.telNumber; // 收货人手机号码
          setLinkname(userName);
          setLinkphone(telNumber);
          setLinkaddress(provinceName + cityName + detailInfo);
          // alert();
          // setMyAddress(userName + provinceName + cityName + countryName + detailInfo + telNumber)
          // console.log(res)
        },
        cancel: function (res) {
            console.log('用户取消选择');
        }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const req = {
      tid,
      cid,
      timestamp,
      sign,
      pwd: form.pwd.value,
      linkname: form.linkname.value,
      linkphone: form.linkphone.value,
      linkaddress: form.linkaddress.value,
    };
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    const data = await fetch(`${baseUrl}api/tickets/exchange`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const jsonData = await data.json();
    alert(jsonData.data.msg);
  };

  return (
    <>
      <Alert show={show} variant="success">
        <Alert.Heading>提示</Alert.Heading>
        <p>{msg}</p>
      </Alert>
      <div className="p-2 mb-4 submit-bg rounded-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="pwd">
            <Form.Label column xs="3">
              券密码
            </Form.Label>
            <Col xs="9">
              <Form.Control
                required
                type="password"
                placeholder="刮开涂层的密码"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="linkwxaddress">
            <Form.Label column xs="3">
            </Form.Label>
            <Col xs="9">
              <Button className="mt-3" variant="primary" type="button" onClick={GetWxAddress}>
                快速输入微信收货地址
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="linkname">
            <Form.Label column xs="3">
              联系人
            </Form.Label>
            <Col xs="9">
              <Form.Control required type="text" placeholder="" defaultValue={linkname} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="linkphone">
            <Form.Label column xs="3">
              手机号
            </Form.Label>
            <Col xs="9">
              <Form.Control required type="text" placeholder="" defaultValue={linkphone} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="linkaddress">
            <Form.Label column xs="3">
              收货地址
            </Form.Label>
            <Col xs="9">
              <Form.Control required type="text" placeholder="" defaultValue={linkaddress} />
            </Col>
          </Form.Group>

          <Stack className="mx-auto">
            <Button className="mt-3" variant="primary" type="submit">
              兑换
            </Button>
          </Stack>
        </Form>
      </div>
    </>
  );
};

const App = () => {
  const [myparams, setMyparams] = useState({});
  const [ticket, setTicket] = useState(null);
  const { importedWX, setImportedWX } = useContext(AppContext);

  useEffect(() => {
    window.document.title='获取联系地址';
   
    // if (!importedWX) {
      // 引入微信JS-SDK
      const script = document.createElement("script");
      script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      script.onload = async () => {
        await initializeWeChatSDK();
        setImportedWX(true);
      };
      document.head.appendChild(script);
    // }
    
  }, []);

  useEffect(async () => {
    window.document.title = "提货券兑换";
    const queryParams = new URLSearchParams(window.location.search);
    const tid = queryParams.get("tid");
    const cid = queryParams.get("cid");
    const timestamp = queryParams.get("timestamp");
    const sign = queryParams.get("sign");
    const req = {
      tid,
      cid,
      timestamp,
      sign,
    };
    const data = await fetch(`${baseUrl}api/tickets/detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const jsoninfo = await data.json();
    if (jsoninfo) {
      setTicket(jsoninfo.data);
      setMyparams(req);
    }
  }, []);

  return (
    <Container className="p-3">
      <TicketInfo ticket={ticket} />
      <hr />
      <SubmitForm myparams={myparams} />
      <div style={{color: 'red'}}>关注“苏浔蟹礼”公众号，点击菜单“自助提货”-“兑换查询”，可查询兑换订单信息</div>
    </Container>
  );
};

export default App;
