import React, { useEffect } from "react";
import {
  Row,
  Container,
  Col,
  Form,
  Button,
  Stack,
  Alert,
} from "react-bootstrap";
import "./App.css";
import { Link } from "react-router-dom";

const First = () => {
    useEffect(()=> {
        window.document.title='提货须知';
    },[]);

  return (
  <Container className="p-3 bg">
    <div>
        <p className="title">2024提货须知</p>
        <p>尊敬的客户您好，感谢大家又一年的支持与陪伴：</p>
        <p>1、2023年大闸蟹提货期已结束，未提货的客户也不必担心，请妥善保管好礼券待至明年蟹季提货；</p>
        <p>3、苏浔蟹礼承诺礼券永不过期、终生有效；</p>
        <p>如有任何疑问可联系全国客服热线或微信公众号客服，我们将真诚为您服务。苏浔蟹礼提货券，不只是一个提货凭证，更是我们与您建立的信任桥梁。</p>
    </div>
    <div className="w-100 d-flex justify-content-center">
        <Button className="w-100" href="#second" variant="primary">我已知晓，开始提货</Button>
    </div>
  </Container>)
};

export default First;
