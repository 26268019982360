import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Container,
  Col,
  Form,
  Button,
  Stack,
  Alert,
} from "react-bootstrap";
import "./App.css";
import { baseUrl } from "./App";
import moment from 'moment';

const OrderStatus = {
  '0': '待处理',
  '201': '已发货',
  '300': '已完成',
  '400': '已取消'
}
const Sorder = () => {
  const [validated, setValidated] = useState(false);
  const [show, toggleShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [linkphone, setLinkphone] = useState('');
  const [code, setCode] = useState('');
  const [orders, setOrders] = useState([]);

  const handleSubmit = async (event) => {
    const data = await fetch(`${baseUrl}api/tickets/getorders?mobile=${linkphone}&code=${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData = await data.json();

    setOrders(jsonData.data);
  };

  useEffect(() => {
    window.document.title = "订单查询";
  }, [orders]);

  const [smsMsg, setSmsMsg] = useState('发送验证码');
  const [smsEnable, setSmsEnable] = useState(true);

  const getCode = async (mobile) => {
    const data = await fetch(`${baseUrl}api/tickets/getsmscode?mobile=${mobile}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData = await data.json();
    return jsonData;
  }
  const smsBtnOnClick = async () => {
    if (!linkphone) {
      alert('手机号不能为空');
      return;
    }
    if (smsEnable)  {
      const data = await getCode(linkphone);
      if (!data.data.success) {
        alert(data.data.msg);
        return;
      }

      let num = 59;
      setSmsEnable(false);
      setSmsMsg(num);
      const timerId = setInterval(() => {
        num = num - 1;
        setSmsMsg(num);
        if (num === 0) {
          clearInterval(timerId)
          setSmsMsg('发送验证码');
          setSmsEnable(true);
        }
      }, 1000);
    }
  }

  return <>
    <Alert show={show} variant="success">
      <Alert.Heading>提示</Alert.Heading>
      <p>{msg}</p>
    </Alert>
    <div className="mt-4 p-2 mb-4 submit-bg rounded-3">
      <Form noValidate validated={validated}>
        <Form.Group as={Row} className="mb-3" controlId="linkphone">
          <Form.Label column xs="3">
            手机号
          </Form.Label>
          <Col xs="9">
            <Form.Control
              required
              type="text"
              placeholder=""
              onChange={(e) => {
                setLinkphone(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="code">
          <Form.Label column xs="3">
            验证码
          </Form.Label>
          <Col xs="4">
            <Form.Control
              required
              type="text"
              placeholder=""
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Col>
          <Col xs="5">
            <Button className="w-100" variant="primary" onClick={smsBtnOnClick}>{smsMsg}</Button>
          </Col>
        </Form.Group>

        <Stack className="mx-auto">
          <Button className="mt-3" variant="primary" onClick={ async () => { await handleSubmit() }}>
            查询兑换
          </Button>
        </Stack>
      </Form>
    </div>
    
    <div className="p-2">
      {orders.map(order => (
        <div className="p-2 mb-4 info-bg rounded-3" key={order.oid}>
          <Row>
            <Col xs={4}>订单编号：</Col>
            <Col xs={8}>{order.oid}</Col>
          </Row>
           <Row>
            <Col xs={4}>卡券编号：</Col>
            <Col xs={8}>{order.tid}</Col>
          </Row>
          <Row>
            <Col xs={4}>卡券名称：</Col>
            <Col xs={8}>{order.tname}</Col>
          </Row>
          <Row>
            <Col xs={4}>订单状态：</Col>
            <Col xs={8}>{OrderStatus[order.status]}</Col>
          </Row>
          <Row>
            <Col xs={4}>运单码(顺丰)：</Col>
            <Col xs={8}><a href={`https://www.sf-express.com/chn/sc/waybill/waybill-detail/${OrderStatus[order.trackingnumber]}`}>{OrderStatus[order.trackingnumber]}</a></Col>
          </Row>
          <Row>
            <Col xs={4}>创建时间：</Col>
            <Col xs={8}>{moment(new Date(order.created_at)).format('yyyy-MM-DD HH:mm:ss')}</Col>
          </Row>
        </div>
      ))}
    </div>
  </>;
};

export default Sorder;
