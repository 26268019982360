export const initializeWeChatSDK = async () => {
    const res = await fetch(
      `https://sxxl.sifan-gift.com/tickets/api/tickets/jsapisign`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const d = (await res.json()).data;

    // 获取微信JS-SDK配置信息
    const config = {
      debug: false,
      appId: "wx830ce71cc6db6cf3",
      timestamp: d.timestamp, // 必填
      nonceStr: d.noncestr, // 必填
      signature: d.sign, // 必填
      jsApiList: ["scanQRCode", "openAddress"], // 必填，需要使用的JS接口列表
    };

    // 初始化微信JS-SDK
    wx.config(config);

    // 配置成功后的回调函数
    wx.ready(() => {
      console.log("WeChat SDK initialized successfully.");
    });

    // 配置失败后的回调函数
    wx.error((error) => {
      console.error("WeChat SDK initialization failed:", error);
    });
  };