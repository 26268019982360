import React, { useContext, useEffect } from "react";
import {
  Container,
  Button,
} from "react-bootstrap";
import "./App.css";
import { AppContext } from "./home";
import { initializeWeChatSDK } from "./utils";

const Second = () => {
  const { importedWX, setImportedWX } = useContext(AppContext);

  const handleScan = () => {

    // 调用微信扫一扫功能
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理；1则直接返回扫描结果
      scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: (res) => {
        const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        window.location.href=result.replace('https://sxxl.sifan-gift.com', '');
        // setCodeV(`扫描结果: ${result}`);
      },
      cancel: () => {
        alert("扫描已取消");
      },
      fail: (err) => {
        console.error("扫描失败:", err);
      },
    });
  };

  useEffect(() => {
    window.document.title='扫一扫';
    // if (!importedWX) {
      // 引入微信JS-SDK
      const script = document.createElement("script");
      script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      script.onload = async () => {
        await initializeWeChatSDK();
        setImportedWX(true);
      };
      document.head.appendChild(script);
    // }
    
  }, []);

  return (
    <Container className="p-3 min-vh-100 d-flex justify-content-center align-items-center">
        <Button variant="primary" className="w-100" onClick={handleScan}>扫一扫</Button>
    </Container>
  );
};

export default Second;
